import { BrowserRouter, Route, Routes } from "react-router-dom";
import { App } from "../pages/App";
import { BusinessShopPage } from "../pages/BusinessShopPage";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentFailed from "../pages/PaymentFailed";
import PaymentSuccess from "../pages/PaymentSuccess";
import PaymentCompleted from "../pages/PaymentCompleted";

export const MainRoute = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={true}
        theme="light"
        transition={Flip}
      />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/:slug" element={<BusinessShopPage />} />
        <Route path="/:slug/paymentfailed" element={<PaymentFailed />} />
        <Route path="/:slug/paymentsuccess" element={<PaymentSuccess />} />
        <Route path="/payment_completed" element={<PaymentCompleted />} />
      </Routes>
    </BrowserRouter>
  );
};
