import { createSlice } from "@reduxjs/toolkit";
import { ProductCartInterface } from "../../interfaces/LocalInterfaces";

interface InitialInterface {
  baseUrl: string;
  initProductsCar: {
    businessName: string;
    initProductsCar: ProductCartInterface[];
  }[];
}

const initialState: InitialInterface = {
  initProductsCar: [],
  baseUrl: process.env.REACT_APP_API_HOST || "https://api.tecopos.com/api",
};

const InitSlice = createSlice({
  initialState,
  name: "initSclice",
  reducers: {
    setInitProductsCar: (state, action) => ({
      ...state,
      initProductsCar: action.payload,
    }),
    setBaseUrl: (state, action) => {
      state.baseUrl = action.payload;
    },
  },
});

export const { setInitProductsCar, setBaseUrl } = InitSlice.actions;

export default InitSlice.reducer;
