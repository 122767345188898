/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { faHeart, faMugHot } from "@fortawesome/free-solid-svg-icons";

import {
  ProductInterface,
  StoreSections,
} from "../interfaces/ServerInterfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalProduct from "../components/modals/modalProduct/ModalProduct";
import ModalGallery from "../components/ModalGallery";
import Loading from "../components/Loading";
import {
  normalizeString,
  printPriceWithCommasAndPeriods,
  scrollToAmount,
  scrollToSection,
  scrollToTop,
} from "../utils/functions";
import PageNotFound from "../components/PageNotFound";
import { RootState } from "../store/store";
import {
  setResultState,
  setSearchInputValue,
} from "../store/slices/resultsReducer";
import ModalRegister from "../components/modals/modalLoginRegister/ModalRegister";
import ModalLogIn from "../components/modals/modalLoginRegister/ModalLogIn";
import Modal from "../components/modals/GenericModal";
import ProfileInfo from "../container/profile/ProfileInfo";
import OrdersHistory from "../container/orders/OrdersHistory";
import PaymentWayForm from "../container/paymentWayForm/PaymentWayForm";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import useServer from "../services/useServerMain";
import { ProductCartInterface } from "../interfaces/LocalInterfaces";
import {
  setActualSlug,
  setCurrentCurrency,
} from "../store/slices/SessionSlice";
import ModalCheckoutForm from "../components/ModalCheckoutForm";
import { useServerOrders } from "../services/useServerOrders";
import { setBaseUrl, setInitProductsCar } from "../store/slices/InitSlice";
import Header from "../container/shopPage/Header";
import { setClearCarShop } from "../store/slices/OrdersSlice";
import { availableBusiness, customShops, regions } from "../utils/staticData";
import ShopView from "../container/shopPage/ShopView";
import useInitialLoad from "../services/useInitialLoad";
import CartShoppingPanel from "../container/shopPage/CartShoppingPanel";
import LoginAndRegisterPanel from "../container/shopPage/LoginAndRegisterPanel";

interface PersonalInfoInterface {
  name: string;
  description: string;
  address: string;
}

export const BusinessShopPage = () => {
  const { initialLoad } = useInitialLoad();

  const { initProductsCar } = useAppSelector((state) => state.init);
  const {
    business,
    configurationsKey,
    products,
    products_with_no_salesCategories,
  } = useAppSelector((state) => state.session);

  const { logOut } = useServer();

  const { CancelOrder } = useServerOrders();

  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [firstLoad, setfirstLoad] = useState<boolean>(true);

  const [cartShopping, setCartShopping] = useState<Array<ProductCartInterface>>(
    initProductsCar?.find(
      (element) => element.businessName === location.pathname
    )?.initProductsCar! ?? []
  );

  const { clearCarShop } = useAppSelector((state) => state.orders);

  const [actualView, setActualView] = useState<string>("shop");

  const [availabilityAlert, setAvailabilityAlert] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [openLogOutModal, setOpenLogOutModal] = useState<boolean>(false);
  const [showModalRegister, setShowModalRegister] = useState<boolean>(false);
  const [showModalLogIn, setShowModalLogIn] = useState<boolean>(false);
  const [deleteCarWarning, setDeleteCarWarning] = useState<{
    status: boolean;
    index: number | null;
  }>({ status: false, index: null });
  const [showModalCheckoutForm, setShowModalCheckoutForm] =
    useState<boolean>(false);
  const [deleteOrderModal, setDeleteOrderModal] = useState<{
    state: boolean;
    id: number | null;
  }>({
    state: false,
    id: null,
  });
  const [seletedProduct, setSeletedProduct] = useState<ProductInterface | null>(
    null
  );

  const [showModalGallery, setShowModalGallery] = useState<boolean>(false);
  const [pageNotFound404, setPageNotFound404] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [userOpen, setUserOpen] = useState<boolean>(false);
  // const [subTotal, setSubTotal] = useState<number>(0);
  const [isLoggingToPay, setIsLoggingToPay] = useState<boolean>(false);

  const [subTotal, setSubTotal] = useState<
    { prices: number; codeCurrency: string }[]
  >([]);

  // const [searchInputValue, setSearchInputValue] = useState("")
  // const [isResultsActive, setIsResultsActive] = useState(false)
  const [productResults, setProductResults] = useState<ProductInterface[]>();

  const { currentCurrency } = useAppSelector(
    (state: RootState) => state.session
  );
  const searchInputValue = useAppSelector(
    (state: RootState) => state.UI.searchInputValue
  );
  const dispatch = useAppDispatch();

  const [online_shop_main_currency, set_online_shop_main_currency] = useState<
    { value: string; name: string; href: string; current: boolean }[]
  >([]);

  const [showInitModal, setshowInitModal] = useState({
    state: false,
    degree: false,
  });

  const navigate = useNavigate();

  const sales_throw_exchange_rate =
    configurationsKey?.find(
      (config: { key: string }) =>
        config.key === "enable_sale_shop_multiple_currencies_from_exchange_rate"
    )?.value === "true";

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsToRemove = ["fbclid", "igshid"];

    paramsToRemove.forEach((param) => {
      if (searchParams.has(param)) searchParams.delete("fbclid");
    });

    // Construir la nueva URL sin el parámetro fbclid
    const newSearch = searchParams.toString();
    const newUrl = `${location.pathname}${newSearch ? "?" + newSearch : ""}`;

    // Navegar a la nueva URL
    navigate(newUrl, { replace: true });
  }, []);

  useEffect(() => {
    const found = customShops.find((item) => item.slug === slug);
    if (!!found) location.href = found.href;

    // getMyOrders({ per_page: 1 });
    //Verificar slug vs id y buscar region correspondiente
    const matchRegion = () => {
      const businessId = availableBusiness.find(
        (item) => item.slug.trim() === slug?.trim()
      )?.id;
      let api = "https://api4.tecopos.com/api";
      if (!businessId) {
        dispatch(setBaseUrl(api));
        return;
      }
      for (const [key, value] of Object.entries(regions)) {
        if (value.some((item) => item === businessId)) {
          api = key;
          break;
        }
      }
      dispatch(setBaseUrl(api));
    };

    // Eliminar parámetros innecesarios de la URL (fbclid, igshid)
    const removeUnwantedParams = () => {
      const searchParams = new URLSearchParams(location.search);
      const paramsToRemove = ["fbclid", "igshid"];

      paramsToRemove.forEach((param) => {
        if (searchParams.has(param)) searchParams.delete(param);
      });

      const newSearch = searchParams.toString();
      const newUrl = `${location.pathname}${newSearch ? "?" + newSearch : ""}`;

      // Navegar a la nueva URL sin los parámetros no deseados
      navigate(newUrl, { replace: true });
    };

    // Redirigir a una tienda personalizada si se encuentra
    const handleCustomShopRedirect = () => {
      const found = customShops.find((item) => item.slug === slug);
      if (!!found) {
        //@ts-ignore
        location.href = found.href;
      }
    };

    // Mostrar modal después de 40 segundos si se cumplen las condiciones
    const handleShowModal = () => {
      if (
        actualView === "shop" &&
        !showModalLogIn &&
        !showModalRegister &&
        !open &&
        sales_throw_exchange_rate
      ) {
        const timer = setTimeout(() => {
          setshowInitModal({
            state: true,
            degree: false,
          });
        }, 40000); // 40 segundos

        return () => clearTimeout(timer);
      }
    };

    // Carga inicial de datos
    const loadInitialData = async () => {
      await initialLoad({
        slug,
        setPageNotFound404,
        setIsLoading,
        setfirstLoad,
        set_online_shop_main_currency,
      });
      dispatch(setActualSlug(slug));
    };

    // Ejecutar las funciones al montar el componente
    matchRegion();
    removeUnwantedParams();
    handleCustomShopRedirect();
    handleShowModal();
    loadInitialData();
  }, []);

  useEffect(() => {
    if (searchInputValue !== "" && products.length > 0) {
      dispatch(setResultState(true));

      let productsResults: ProductInterface[] = [];

      products.map((prod) =>
        prod.data.map((dat) => {
          if (
            normalizeString(dat.name)?.includes(
              normalizeString(searchInputValue)
            )
          ) {
            productsResults.push(dat);
          }
        })
      );

      setProductResults(productsResults);

      scrollToAmount(1300);
    }
  }, [products]);

  useEffect(() => {
    (async () => {
      const subTotalTemp: { [currency: string]: number } = {};

      for (const product of cartShopping) {
        const quantity = product.quantity;
        const price = product.onSale
          ? product?.onSalePrice?.amount
          : product.price.price;
        const currency = product.price.codeCurrency;

        subTotalTemp[currency] =
          (subTotalTemp[currency] || 0) + price * quantity;
      }

      const formattedSubtotal = Object.entries(subTotalTemp).map(
        ([codeCurrency, prices]) => ({
          prices,
          codeCurrency,
        })
      );

      setSubTotal(formattedSubtotal);
    })();
    setAvailabilityAlert(
      cartShopping.some(
        (product) =>
          product.quantity > product.quantityInStock &&
          product.type !== "SERVICE" &&
          product.type !== "ADDON" &&
          product.type !== "MENU"
      )
    );

    if (!firstLoad) {
      if (
        initProductsCar.some(
          (element) => element.businessName === location.pathname
        )
      ) {
        dispatch(
          setInitProductsCar(
            initProductsCar.map((element) => {
              if (element.businessName === location.pathname) {
                return {
                  businessName: location.pathname,
                  initProductsCar: cartShopping,
                };
              } else {
                return element;
              }
            })
          )
        );
      } else {
        dispatch(
          setInitProductsCar([
            ...initProductsCar,
            { businessName: location.pathname, initProductsCar: cartShopping },
          ])
        );
      }
    }

    // dispatch(setInitProductsCar(cartShopping))
  }, [cartShopping]);

  useEffect(() => {
    setCartShopping(
      initProductsCar?.find(
        (element) => element.businessName === location.pathname
      )?.initProductsCar! ?? []
    );
  }, [business]);

  useEffect(() => {
    setCartShopping([]);
    dispatch(setClearCarShop(false));
  }, [clearCarShop]);

  //Functions that take care of filtering products when changing the currency type
  const productsFilteredByCurrencyType = () => {
    return products?.map((section) => {
      const filterData = section.data.filter((prod) => {
        if (prod.onSale) {
          return (
            prod?.onSalePrice?.codeCurrency ===
            online_shop_main_currency?.filter((currency) => currency.current)[0]
              .value
          );
        } else {
          return prod.prices.some(
            (price) =>
              price.codeCurrency ===
              online_shop_main_currency.filter(
                (currency) => currency.current
              )[0].value
          );
        }
      });

      if (filterData.length >= 1) {
        return {
          id: section.id,
          title: section.title,
          data: filterData,
        };
      }
    });
  };

  const products_with_no_salesCategories_FilteredByCurrencyType = () => {
    return {
      data: products_with_no_salesCategories?.data.filter((prod) => {
        if (prod.onSale) {
          return (
            prod?.onSalePrice?.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
          );
        } else {
          return prod.prices.some(
            (price) =>
              price.codeCurrency ===
              online_shop_main_currency.filter(
                (currency) => currency.current
              )[0].value
          );
        }
      }),
      id: products_with_no_salesCategories?.id,
      title: products_with_no_salesCategories?.title,
    } as StoreSections;
  };
  //-----------------------------------------------------------
  const addCart = (value: ProductInterface) => {
    let productTemp: any;

    if (value.type === "VARIATION") {
      productTemp = cartShopping.find(
        (item) => item.id === value.id && item.variationId === value.variationId
      );
    } else {
      productTemp = cartShopping.find((item) => item.id === value.id);
    }

    if (!productTemp) {
      let newProductTemp: ProductCartInterface;
      newProductTemp = {
        name: value.name,
        price: value.prices[0],
        quantity: 1,
        images: value.images[0],
        id: value.id,
        variationId: value.type === "VARIATION" ? value.variationId : null,
        suggested: value.suggested,
        onSale: value.onSale,
        onSalePrice: value.onSalePrice!,
        quantityInStock: value.totalQuantity,
        type: value.type,
      };
      const cartShoppingTemp = [...cartShopping, newProductTemp];
      setCartShopping(cartShoppingTemp);
    } else {
      const cartShoppingTemp = cartShopping.map((item) => {
        if (item.type === "VARIATION") {
          if (
            item.id === productTemp?.id &&
            item.variationId === productTemp.variationId
          ) {
            return {
              ...item,
              quantity: productTemp.quantity + 1,
            };
          } else {
            return item;
          }
        } else {
          if (item.id === productTemp?.id) {
            return {
              ...item,
              quantity: productTemp.quantity + 1,
            };
          } else {
            return item;
          }
        }
      });
      setCartShopping(cartShoppingTemp);
    }

    setOpen(true);
  };

  const quantityProduct = (
    value: ProductCartInterface,
    quantityValue: number
  ) => {
    let productTemp = value;
    const cartShoppingTemp = cartShopping.map((item) => {
      if (item.type === "VARIATION") {
        if (
          item.id === productTemp?.id &&
          item.variationId === productTemp.variationId
        ) {
          return {
            ...item,
            quantity: quantityValue,
          };
        } else {
          return item;
        }
      } else {
        if (item.id === productTemp?.id) {
          return {
            ...item,
            quantity: quantityValue,
          };
        } else {
          return item;
        }
      }
    });
    setCartShopping(cartShoppingTemp);
  };

  const removeQuantityCart = (value: ProductCartInterface) => {
    let productTemp = value;
    const cartShoppingTemp = cartShopping.map((item) => {
      if (item.type === "VARIATION") {
        if (
          item.id === productTemp?.id &&
          item.variationId === productTemp.variationId
        ) {
          return {
            ...item,
            quantity: productTemp.quantity - 1,
          };
        } else {
          return item;
        }
      } else {
        if (item.id === productTemp?.id) {
          return {
            ...item,
            quantity: productTemp.quantity - 1,
          };
        } else {
          return item;
        }
      }
    });
    setCartShopping(cartShoppingTemp);
  };

  const addProductCart = (value: ProductCartInterface) => {
    let productTemp = value;
    const cartShoppingTemp = cartShopping.map((item) => {
      if (item.type === "VARIATION") {
        if (
          item.id === productTemp?.id &&
          item.variationId === productTemp.variationId
        ) {
          return {
            ...item,
            quantity: productTemp.quantity + 1,
          };
        } else {
          return item;
        }
      } else {
        if (item.id === productTemp?.id) {
          return {
            ...item,
            quantity: productTemp.quantity + 1,
          };
        } else {
          return item;
        }
      }
    });
    setCartShopping(cartShoppingTemp);
  };

  const deletedProductFromCart = (value: ProductCartInterface) => {
    let cartShoppingTemp: ProductCartInterface[];

    if (value.type === "VARIATION") {
      cartShoppingTemp = cartShopping.filter(
        (item) => item.variationId !== value.variationId
      );
    } else {
      cartShoppingTemp = cartShopping.filter((item) => item.id !== value.id);
    }

    setCartShopping(cartShoppingTemp);
  };

  const sendWhatsApp = (values: PersonalInfoInterface) => {
    if (business?.phones.length !== 0) {
      const phoneToSend = business?.phones[0].number
        .replace(/[^\w\s]/g, "")
        .replace(/\s/g, "");
      let url = `https://api.whatsapp.com/send?phone=${phoneToSend}`;

      const listProducts = cartShopping
        .map((item) => {
          let itemPrice = item.price.price;
          if (item.onSale && item.onSalePrice) {
            itemPrice = item?.onSalePrice?.amount;
          }
          return `(x${item.quantity}) ${
            item.name
          } - ${printPriceWithCommasAndPeriods(itemPrice * item.quantity)}`;
        })
        .join("\n");

      const cleanValue = (value: string) =>
        value.replace(/[^\w\s]/g, " ").replace(/\s/g, " ");

      const payload = `Hola!, quería ordenar un pedido.\n\nNombre: ${cleanValue(
        values.name
      )}\nDirección: ${cleanValue(values.address)}\nDescripción: ${cleanValue(
        values.description
      )}\n\n${listProducts}\n\nTotal: ${subTotal
        .map(
          (st) =>
            `${printPriceWithCommasAndPeriods(st.prices)} ${st.codeCurrency}`
        )
        .join(" ")}`;
      url += `&text=${encodeURI(payload)}`;

      window.open(url);
      setShowModalCheckoutForm(false);
    }

    // Cleaning CarShop after ordering
    setCartShopping([]);
    setOpen(false);
  };

  const showProduct = (value: ProductInterface) => {
    setSeletedProduct(value);
    setShowModal(true);
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();

    dispatch(setSearchInputValue(normalizeString(e.target.value)));

    dispatch(setResultState(true));

    let productsResults: ProductInterface[] = [];

    products_with_no_salesCategories_FilteredByCurrencyType().data.map(
      (prod) => {
        if (
          normalizeString(prod.name).includes(normalizeString(e.target.value))
        ) {
          productsResults.push(prod);
        }
      }
    );

    productsFilteredByCurrencyType().map((prod) =>
      prod?.data.map((dat) => {
        if (
          normalizeString(dat.name).includes(normalizeString(e.target.value))
        ) {
          productsResults.push(dat);
        }
      })
    );

    setProductResults(productsResults);

    scrollToSection("resultSection");
  };

  const handle_online_shop_main_currency_sortOptions = (index: number) => {
    set_online_shop_main_currency((prevOptions) =>
      prevOptions.map((sortOption, i) => {
        if (i === index && !sortOption.current) {
          // aplying_online_shop_main_currency_options(sortOption.value)
          dispatch(setCurrentCurrency(sortOption.value));
          return {
            ...sortOption,
            current: true,
          };
        } else if (i !== index && sortOption.current) {
          return {
            ...sortOption,
            current: false,
          };
        } else {
          return sortOption;
        }
      })
    );
  };

  if (searchInputValue === "") {
    dispatch(setResultState(false));
  }

  if (isLoading) {
    return <Loading />;
  }

  if (pageNotFound404) {
    return <PageNotFound />;
  }
  const enable_delivery =
    business?.configurationsKey.find(
      (config) => config?.key === "enable_delivery"
    )?.value === "true";
  const when_shop_create_preorder =
    business?.configurationsKey.filter(
      (config) => config.key === "when_shop_create_preorder"
    )[0].value === "true";
  const enable_to_sale_in_negative = business?.configurationsKey.some(
    (config) => config.key === "enable_to_sale_in_negative"
  )
    ? business?.configurationsKey.filter(
        (config) => config.key === "enable_to_sale_in_negative"
      )[0].value === "true"
    : false;
  const online_shop_show_current_currency_modal =
    business?.configurationsKey.some(
      (config) => config.key === "online_shop_show_current_currency_modal"
    )
      ? business?.configurationsKey.filter(
          (config) => config.key === "online_shop_show_current_currency_modal"
        )[0].value === "true"
      : false;

  return (
    <div className="bg-white" key={slug}>
      {/* Hero section */}
      {actualView === "profile-info" && (
        <ProfileInfo setActualView={setActualView} />
      )}

      {actualView === "orders-history" && (
        <OrdersHistory
          setActualView={setActualView}
          products={products}
          addCart={addCart}
        />
      )}

      {actualView === "checkout-form" && (
        <PaymentWayForm
          setActualView={setActualView}
          products={cartShopping}
          subTotal={subTotal}
          setOpen={setOpen}
          deletedProductFromCart={deletedProductFromCart}
          setCartShopping={setCartShopping}
          online_shop_main_currency={
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
          }
          enable_pick_up_in_store={
            business?.configurationsKey.filter(
              (config) => config.key === "enable_pick_up_in_store"
            )[0].value === "true"
          }
          enable_delivery={enable_delivery}
          when_shop_create_preorder={when_shop_create_preorder}
        />
      )}

      {actualView === "shop" && (
        <ShopView
          showProduct={showProduct}
          addCart={addCart}
          cartShopping={cartShopping}
          productResults={productResults}
          productsFilteredByCurrencyType={productsFilteredByCurrencyType}
          products_with_no_salesCategories_FilteredByCurrencyType={
            products_with_no_salesCategories_FilteredByCurrencyType
          }
          online_shop_main_currency={online_shop_main_currency}
        />
      )}

      <footer className="bg-slate-50 flex  ">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8  md:order-1 md:mt-0">
            <p className="text-center text-base text-slate-400">
              &copy;{new Date().getFullYear()} {business?.name}.
            </p>
            <a
              href="https://tienda.tecopos.com"
              className="text-center text-base text-slate-400"
            >
              &copy; {new Date().getFullYear()} Tecopos Tienda. ThreeFace. Todos
              los derechos reservados. Hecho con
              <FontAwesomeIcon
                icon={faHeart}
                className="h-3 w-3 mx-1 text-slate-500"
                aria-hidden="true"
              />{" "}
              y{" "}
              <FontAwesomeIcon
                icon={faMugHot}
                className="h-3 w-3  text-slate-500"
                aria-hidden="true"
              />
              .{" "}
            </a>
          </div>
        </div>
      </footer>

      {showModalGallery && (
        <ModalGallery
          images={business?.images}
          onClick={() => setShowModalGallery(false)}
          index={0}
        />
      )}
      {showModal && (
        <ModalProduct
          product={seletedProduct}
          onClick={() => setShowModal(false)}
          addCart={addCart}
          includeShop={business?.includeShop ? true : false}
          when_shop_create_preorder={when_shop_create_preorder}
          enable_to_sale_in_negative={enable_to_sale_in_negative}
        />
      )}
      {showModalCheckoutForm && (
        <ModalCheckoutForm
          onClick={() => {
            setShowModalCheckoutForm(false);
          }}
          onSendWhatsApp={sendWhatsApp}
        />
      )}

      {showModalRegister && (
        <ModalRegister
          onClick={() => setShowModalRegister(false)}
          setShowModalLogIn={setShowModalLogIn}
        />
      )}

      {showModalLogIn && (
        <ModalLogIn
          onClick={() => {
            setShowModalLogIn(false);
            if (isLoggingToPay) {
              // setActualView("checkout-form")
              scrollToTop();
            }
            setIsLoggingToPay(false);
          }}
          setShowModalRegister={setShowModalRegister}
        />
      )}

      {openLogOutModal && (
        <Modal state={openLogOutModal} close={setOpenLogOutModal}>
          <div className="flex items-center flex-col">
            <span>¿Seguro que desea cerrar sesión?</span>

            <button
              className="bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4"
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
                setOpenLogOutModal(false);
                logOut();
              }}
            >
              Aceptar
            </button>
          </div>
        </Modal>
      )}

      {deleteCarWarning.status && (
        <Modal state={deleteCarWarning.status} close={setDeleteCarWarning}>
          <div className="flex items-center flex-col">
            <span className="text-center">
              Si cambia la moneda se vaciará el carrito.
              <br />
              ¿Seguro que desea continuar?
            </span>

            <button
              className="bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4"
              onClick={(e) => {
                e.preventDefault();
                handle_online_shop_main_currency_sortOptions(
                  deleteCarWarning?.index as number
                );
                setDeleteCarWarning({
                  status: false,
                  index: null,
                });
                setCartShopping([]);
              }}
            >
              Aceptar
            </button>
          </div>
        </Modal>
      )}

      {deleteOrderModal.state && (
        <Modal state={deleteOrderModal.state} close={setDeleteOrderModal}>
          <div className="flex items-center flex-col">
            <span className="text-center">
              ¿Seguro que desea cancelar la órden?
            </span>

            <button
              className="bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4"
              onClick={(e) => {
                e.preventDefault();
                CancelOrder(deleteOrderModal?.id!);
                setDeleteOrderModal({
                  state: false,
                  id: null,
                });
              }}
            >
              Aceptar
            </button>
          </div>
        </Modal>
      )}

      {showInitModal.state &&
        actualView === "shop" &&
        online_shop_show_current_currency_modal &&
        !showModalLogIn &&
        !showModalRegister &&
        !open && (
          <Modal state={showInitModal.state} close={setshowInitModal}>
            {!showInitModal.degree ? (
              <div>
                <p className="text-center">
                  Esta visualizando los precios en{" "}
                  <span className="font-semibold">{currentCurrency}</span>.
                  ¿Desea continuar en esa moneda?
                </p>

                <div className="flex justify-between w-full gap-x-2">
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white w-40 h-10 rounded-md mt-4"
                    onClick={() => {
                      setshowInitModal({
                        state: true,
                        degree: true,
                      });
                    }}
                  >
                    No
                  </button>
                  <button
                    className={
                      "bg-orange-500 text-white w-40 h-10 rounded-md mt-4"
                    }
                    onClick={() => {
                      setshowInitModal({
                        state: false,
                        degree: false,
                      });
                    }}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p className="mb-4 text-center">
                  Seleccione la moneda en la que desea visualizar sus precios.
                </p>

                <div className="w-full flex items-center justify-center">
                  {online_shop_main_currency.map((coin, indx) => (
                    <p
                      className="relative cursor-pointer inline-block text-right  mx-2 rounded-md shadow-md bg-gray-800 h-8 px-2 mt-1 pt-1 lg:mt-0 text-white"
                      onClick={() => {
                        handle_online_shop_main_currency_sortOptions(indx);
                        setshowInitModal({
                          state: false,
                          degree: false,
                        });
                      }}
                    >
                      {coin.name}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </Modal>
        )}

      <Header
        setActualView={setActualView}
        actualView={actualView}
        business={business}
        cartShopping={cartShopping}
        searchInputValue={searchInputValue}
        setOpen={setOpen}
        handleSearchSubmit={handleSearchSubmit}
        setUserOpen={setUserOpen}
        online_shop_main_currency={online_shop_main_currency}
        handle_online_shop_main_currency_sortOptions={
          handle_online_shop_main_currency_sortOptions
        }
        products={products}
        setCartShopping={setCartShopping}
      />

      {/* Bolsa de compra */}
      <CartShoppingPanel
        open={open}
        setOpen={setOpen}
        cartShopping={cartShopping}
        removeQuantityCart={removeQuantityCart}
        deletedProductFromCart={deletedProductFromCart}
        quantityProduct={quantityProduct}
        addProductCart={addProductCart}
        actualView={actualView}
        subTotal={subTotal}
        availabilityAlert={availabilityAlert}
        setActualView={setActualView}
        setIsLoggingToPay={setIsLoggingToPay}
        setShowModalLogIn={setShowModalLogIn}
        setShowModalCheckoutForm={setShowModalCheckoutForm}
      />

      {/* Login */}
      <LoginAndRegisterPanel
        userOpen={userOpen}
        setUserOpen={setUserOpen}
        availabilityAlert={availabilityAlert}
        setActualView={setActualView}
        setShowModalLogIn={setShowModalLogIn}
        setShowModalRegister={setShowModalRegister}
        setOpenLogOutModal={setOpenLogOutModal}
      />
    </div>
  );
};
